<template>
  <v-row justify="space-around">
      <v-dialog
        transition="dialog-bottom-transition"
        max-width="600"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn style="color: #ffffff;"
          color="#e36d26b5"
            v-bind="attrs"
            v-on="on"
          >
          <b v-if="!(language.indexOf('es') === -1)">Programar una Demo</b>
          <b v-if="(language.indexOf('es') === -1)">Schedule a Demo</b>
          </v-btn>
        </template>
        <template v-slot:default="dialog">
          <v-card>
              <v-card-actions class="justify-end" style="position: absolute; right: 0px; padding: 10px;">
              <v-btn style="color: #ffffff;"
                color="#ed9058"
                @click="dialog.value = false"
              >x</v-btn>
            </v-card-actions>
            <v-card-text>
              <DemoVicariusRegistro />
            </v-card-text>
          </v-card>
        </template>
      </v-dialog>
  </v-row>
</template>

<script>
// import DemoVicariusRegistro from './DemoVicariusRegistro.vue'
import DemoVicariusRegistro from './SetMore.vue'
import { VDialog, VBtn, VCard, VRow, VCardActions, VCardText } from 'vuetify/lib'
// registrar VSpacer VIcon
export default {
  data () {
    return {
      dialog: false,
      notifications: false,
      sound: true,
      widgets: false
    }
  },
  props: {
    msg: String,
    language: String
  },
  components: {
    DemoVicariusRegistro, VDialog, VBtn, VCard, VRow, VCardActions, VCardText
  }
}
</script>
