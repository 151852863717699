<template>
    <div>
        <VmwareLanding :language = "dominioactual" />
    </div>
</template>

<script>
import VmwareLanding from './VmwareLanding.vue'

export default {
  components: {
    VmwareLanding
  },
  created () {
    this.track()
  },
  data () {
    return {
      dominioactual: navigator.language
    }
  },
  methods: {
    track () {
      this.$gtag.pageview({ page_path: '/solutions/itservices/vmware' })
    }
  }
}
</script>

<style>

</style>
